import React from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import CountUp from 'react-countup'
import { P } from 'components/typography/Paragraph'

interface Props {
	number: number
	title: string
	text: string
}

const StatCounter = ({ number, title, text }: Props) => {
	return (
		<div className="flex flex-col justify-center text-center">
			<CountUp end={number} separator="," duration={2}>
				{({ countUpRef, start }) => (
					<VisibilitySensor onChange={start} delayedCall>
						<span ref={countUpRef} className="mb-0 text-4xl font-extrabold text-ab-100" />
					</VisibilitySensor>
				)}
			</CountUp>
			<span className="mb-1 text-2xl font-semibold">{title}</span>
			<P size="md" className="mx-auto w-3/4 sm:w-full">
				{text}
			</P>
		</div>
	)
}

export default StatCounter
