import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { Col, Row } from 'components/grid'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
// @ts-ignore
import Fade from 'react-reveal/Fade'
// @ts-ignore
import Slide from 'react-reveal/Slide'

import VideoEmbed from '../global/VideoEmbed'
import SchoolResultsSlider from 'components/digital-solutions/SchoolResultsSlider'
import { H2 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'

const Grow = () => {
	return (
		<>
			<Row center className="mb-10">
				<Col width="w-full md:w-1/2">
					<Fade bottom>
						<img src="/images/digital-solutions/instruction-cycle.png" alt="instruction cycle" />
					</Fade>
				</Col>
			</Row>
			<Row center className="mb-32">
				<Col>
					<Slide left>
						<H2 className="mb-2">Analyze</H2>
						<P className="mb-5">
							Powerful dashboards and reports assess student performance in real time at the classroom, school, and district levels and allow schools to use
							equity-driven data to transform instruction and outcomes.
						</P>
					</Slide>
				</Col>
				<Col>
					<Fade bottom>
						<Carousel showThumbs={false} dynamicHeight showStatus={false} infiniteLoop>
							<div>
								<img src="/images/digital-solutions/bilingual-school-dashboard.jpg" alt="bilingual school dashboard" />
							</div>
							<div>
								<img src="/images/digital-solutions/classroom-dashboard.jpg" alt="classroom dashboard" />
							</div>
						</Carousel>
					</Fade>
				</Col>
			</Row>
			<Row center className="mb-32">
				<Col>
					<Slide left>
						<H2 className="mb-2">Instruct</H2>
						<P className="mb-5">Equipped with insight into what each child knows and needs to learn next, teachers effectively differentiate instruction for ALL.</P>
					</Slide>
				</Col>
				<Col>
					<Fade bottom>
						<Carousel showThumbs={false} dynamicHeight showStatus={false} infiniteLoop>
							<div>
								<img src="/images/digital-solutions/status-of-the-class.jpg" alt="status of the class" />
							</div>
							<div>
								<img src="/images/digital-solutions/schoolpace-connect-teacher.jpg" alt="schoolpace for teachers" />
							</div>
							<div>
								<img src="/images/digital-solutions/schoolpace-connect-lesson.jpg" alt="schoolpace lessons" />
							</div>
							<div>
								<img src="/images/digital-solutions/schoolpace-connect-student.jpg" alt="schoolpace for students" />
							</div>
							<div>
								<img src="/images/digital-solutions/lets-quit.jpg" alt="lets quick book cover" style={{ maxHeight: 453, width: 'auto' }} />
							</div>
						</Carousel>
					</Fade>
				</Col>
			</Row>
			<Row center className="mb-32">
				<Col>
					<Slide left>
						<H2 className="mb-2">Practice</H2>
						<P className="mb-5">
							Students build skills, stamina, and reader identities through daily practice in high-quality texts aligned to the same skills they are learning.
						</P>
					</Slide>
				</Col>
				<Col>
					<Fade bottom>
						<Carousel showThumbs={false} dynamicHeight showStatus={false} infiniteLoop>
							<div>
								<VideoEmbed title="ARC Bookshelf" src="https://player.vimeo.com/video/491750618" />
							</div>
							<div>
								<VideoEmbed title="SchoolPace Connect for Teachers" src="https://player.vimeo.com/video/491770786" />
							</div>
						</Carousel>
					</Fade>
				</Col>
			</Row>
			<Row center>
				<Col>
					<Slide left>
						<H2 className="mb-2">Grow</H2>
						<P className="mb-5">
							ARC partner districts create student literacy growth day by day and year after year using a carefully crafted collection of tools built on the latest
							research and years of proven experience.
						</P>
					</Slide>
				</Col>
				<Col>
					<Fade bottom>
						<SchoolResultsSlider />
					</Fade>
				</Col>
			</Row>
		</>
	)
}

export default Grow
