import { SchoolPaceStats } from 'api/schoolpace/get-stats'
import axios from 'axios'
import StatCounter from 'components/digital-solutions/StatCounter'
import StatCounterSkeleton from 'components/digital-solutions/StatCounterSkeleton'
import { Col, Row } from 'components/grid'
import React, { useEffect, useState } from 'react'
// @ts-ignore
import Fade from 'react-reveal/Fade'
export interface IStats {
	classrooms: number
	districts: number
	schools: number
	students: number
	states: number
}

const Stats = () => {
	const [stats, setStats] = useState<IStats>({ districts: 863, classrooms: 46141, schools: 4097, students: 2126539, states: 45 })

	useEffect(() => {
		async function getSchoolPaceStats() {
			try {
				const { data } = await axios.get<SchoolPaceStats>('/api/schoolpace/get-stats')
				if (typeof data !== 'object') throw new Error('Something went wrong getting schoolpace stats')
				setStats({ districts: data.d, classrooms: data.c, schools: data.s, students: data.st, states: data.sp })
			} catch (e: any) {
				setStats({ districts: 863, schools: 4097, classrooms: 46141, students: 2126539, states: 45 })
				if (axios.isAxiosError(e)) {
					console.log(e.response?.data)
				} else console.log(e.message)
			}
		}
		getSchoolPaceStats()
	}, [])

	return stats ? (
		<Row className="my-10">
			<Col width="w-full sm:w-1/2 lg:w-1/4 mb-10 sm:mb-6">
				<Fade bottom>
					<StatCounter number={stats.districts} title="Districts" text="Districts use real-time data to accelerate reading and writing achievement." />
				</Fade>
			</Col>
			<Col width="w-full sm:w-1/2 lg:w-1/4 mb-10 sm:mb-6">
				<Fade bottom delay={200}>
					<StatCounter number={stats.students} title="Students" text="Students know what they can do and what they need to learn next." />
				</Fade>
			</Col>
			<Col width="w-full sm:w-1/2 lg:w-1/4 mb-10 sm:mb-6">
				<Fade bottom delay={400}>
					<StatCounter number={stats.schools} title="Schools" text="Schools ensure every student makes adequate yearly progress." />
				</Fade>
			</Col>
			<Col width="w-full sm:w-1/2 lg:w-1/4 mb-10 sm:mb-6">
				<Fade bottom delay={600}>
					<StatCounter number={stats.states} title="States" text="Students across the U.S. build literacy and agency to change the world." />
				</Fade>
			</Col>
		</Row>
	) : (
		<Row>
			<Col width="w-full sm:w-1/2 lg:w-1/4 mb-10 sm:mb-6">
				<StatCounterSkeleton />
			</Col>
			<Col width="w-full sm:w-1/2 lg:w-1/4 mb-10 sm:mb-6">
				<StatCounterSkeleton />
			</Col>
			<Col width="w-full sm:w-1/2 lg:w-1/4 mb-10 sm:mb-6">
				<StatCounterSkeleton />
			</Col>
			<Col width="w-full sm:w-1/2 lg:w-1/4 mb-10 sm:mb-6">
				<StatCounterSkeleton />
			</Col>
		</Row>
	)
}

export default Stats
