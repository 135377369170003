import { AddOnPackage } from 'components/digital-solutions/AddOnPackage'
import ForTabs from 'components/digital-solutions/ForTabs'
import Grow from 'components/digital-solutions/Grow'
import Stats from 'components/digital-solutions/Stats'
import LargeForm from 'components/forms/LargeForm'
import SectionTitle from 'components/global/SectionTitle'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import OverlayVideo from 'components/overlay-video'
import { H1 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import React from 'react'
// @ts-ignore
import Fade from 'react-reveal/Fade'
// @ts-ignore
import Slide from 'react-reveal/Slide'

export default function DigitalSolutionsPage() {
	return (
		<Layout pageName="Digital Solutions">
			<Section>
				<div className="absolute top-0 left-0 z-0 h-full w-full bg-cover bg-bottom" style={{ backgroundImage: `url('/images/digital-solutions/hero.jpg')` }}>
					<div className="white-transparent-gradient-medium h-full w-full"></div>
				</div>
				<Row center className="relative z-auto pt-10 sm:pt-20">
					<Col width="w-full sm:w-2/3">
						<Fade top>
							<div>
								<img src="/images/logos/schoolpace-connect-logo-halo.png" alt="schoolpace logo" />
							</div>
						</Fade>
					</Col>
				</Row>
				<Row center className="relative z-auto -mt-4 md:-mt-12">
					<Col width="w-full md:w-5/6 xl:w-2/3" className="mt-3 text-center">
						<Fade top delay={400}>
							<H1 className="mb-3">Transform Your System</H1>
						</Fade>
					</Col>
				</Row>
			</Section>
			<Section>
				<Stats />
			</Section>
			<Section margin="mt-20 sm:mt-32">
				<Grow />
			</Section>
			<Section margin="mt-40" id="tools">
				<Fade bottom>
					<SectionTitle title="Tools to Succeed in Any Learning Environment" />
				</Fade>
				<Row>
					<Col gutter="none">
						<ForTabs />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20 sm:mt-40" id="watch">
				<Row className="items-center">
					<Col width="w-full md:w-5/12" className="">
						<Slide left>
							<div className="-mr-0 md:-mr-48">
								<OverlayVideo title="SchoolPace Revolutionizes Data" />
							</div>
						</Slide>
					</Col>
					<Col width="w-full md:w-7/12" className="bg-white py-0 pl-4 sm:bg-slate-50 md:py-40 md:pl-56">
						<Slide right>
							<span className="mt-3 mb-1 inline-block text-3xl font-medium">Watch It in Action</span>
							<P size="md" className="mb-3">
								Hear from district leaders how ARC’s seamless digital supports have revolutionized performance management and accelerated reading growth for their
								students.
							</P>
						</Slide>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20 sm:mt-40" id="digitalSupports">
				<Fade bottom>
					<SectionTitle
						title="ARC Core Digital Support Package"
						text="Power up your ARC Core implementation with our Digital Support Package, now available to add on with your Connect subscription."
					/>
				</Fade>
				<Row center>
					<AddOnPackage />
				</Row>
			</Section>
			<Section margin="mt-20 md:mt-40">
				<Row className="justify-center pb-6">
					<Col width="w-full md:w-5/6 lg:w-3/4 xl:w-1/2">
						<LargeForm pageName="Digital Solutions" />
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}
