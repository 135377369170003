import React from 'react'
import Skeleton from 'react-loading-skeleton'

const StatCounterSkeleton = () => {
	return (
		<div className="flex flex-col justify-center text-center mt-10">
			<Skeleton width={100} height={35} className="mb-3" />
			<Skeleton width={130} height={25} className="mb-3" />
			<Skeleton width={200} height={14} />
			<Skeleton width={200} height={14} />
			<Skeleton width={200} height={14} />
		</div>
	)
}

export default StatCounterSkeleton
